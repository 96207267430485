<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import Fixtures from "../../../services/Fixtures";
import modalScoreGoal from "../../../components/modals/simulator/modalScoreGoal.vue";
import modalYellowCard from "../../../components/modals/simulator/modalYellowCard.vue";
import modalRedCard from "../../../components/modals/simulator/modalRedCard.vue";
import modalSubstitution from "../../../components/modals/simulator/modalSubstitution.vue";
import modalMOTM from "../../../components/modals/simulator/modalMOTM.vue";
import Events from "./components/Events.vue";

export default {
  components: {
    Layout,
    PageHeader,
    Events,
    modalScoreGoal,
    modalYellowCard,
    modalRedCard,
    modalSubstitution,
    modalMOTM
  },
  page: {
    title: "Match Simulator",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Match Simulator",
      items: [
        {
          text: "Matches",
        },
        {
          text: "Match Simulator",
        },
      ],
      eventTeam: 0, //0 for home, 1 for away
      match_id: this.$route.params.id,
      match: null,
      second_player_id: null,
      canEdit: false,
      timer: { minutes: 0, seconds: 0 },
      homeSubs: [],
      awaySubs: [],
      phases: [
        {
          phase_id: 0,
          phase_short: "S",
          matchTime: "Match not started",
          time: "00:00",
        },
        {
          phase_id: 1,
          phase_short: "KO",
          matchTime: "Kick off",
          time: "00:01",
        },
        {
          phase_id: 2,
          phase_short: "HT",
          matchTime: "Halftime",
          time: "45:00",
        },
        {
          phase_id: 3,
          phase_short: "SHS",
          matchTime: "Second Half",
          time: "45:01",
        },
        {
          phase_id: 4,
          phase_short: "FT",
          matchTime: "Full time",
          time: "90:00",
        },
        {
          phase_id: 5,
          phase_short: "EOM",
          matchTime: "End of Match",
          time: "90:00",
        },
      ],
      events: [],
      match_status: 0,
      homeTeam: {
        home_team_name: "",
        home_team_logo: "",
        score: 0,
        roster: [],
      },
      awayTeam: {
        away_team_name: "",
        away_team_logo: "",
        score: 0,
        roster: [],
      },
      rosterCreated: true,
      isBusy: false,
      error: null,
      showModal: false,
      modalData: {},
      refresh:false,
    };
    
  },
  created() {
    this.getMatch();
    this.getLineups();
    this.getEvents();
    if (this.canEdit === false) this.startTimer();
  },

  methods: {
    async getMatch() {
      try {
        const response = await Fixtures.getMatch(this.match_id);
        this.homeTeam.home_team_name = response.data.match.home_team_name;
        this.homeTeam.home_team_logo = response.data.match.home_team_logo;
        this.awayTeam.away_team_name = response.data.match.away_team_name;
        this.awayTeam.away_team_logo = response.data.match.away_team_logo;
        this.homeTeam.score = response.data.match.score.ft_score_home || 0;
        this.awayTeam.score = response.data.match.score.ft_score_away || 0;
        this.match = response.data.match;
        this.match_status = this.getStatus();
        if (this.getStatus() === 0) {
          this.timer.minutes = 0;
          this.timer.seconds = 0;
          return;
        }
        let time;
        if (this.getStatus() === 1) {
          time = new Date(response.data.match.progress.KO_time);
          this.timer.minutes = Math.floor((new Date() - time) / 1000 / 60);
          this.timer.seconds = Math.floor((new Date() - time) / 1000) % 60;
        }
        if (this.getStatus() === 2) {
          this.timer.minutes = 45;
          this.timer.seconds = 0;
          return;
        }
        if (this.getStatus() === 3) {
          time = new Date(response.data.match.progress.SHS_time);
          this.timer.minutes = 45 + Math.floor((new Date() - time) / 1000 / 60);
          this.timer.seconds = Math.floor((new Date() - time) / 1000) % 60;
        }
        if (this.getStatus() === 4) {
          this.timer.minutes = 90;
          this.timer.seconds = 0;
          return;
        }
      } catch (error) {
        this.failedmsg(error.response.data);
      }
    },
    async createRoster() {
      try {
        this.isBusy = true;
        await Fixtures.createRoster(this.match_id);
        this.rosterCreated = true;
        this.isBusy = false;
        this.getLineups();
      } catch (error) {
        this.failedmsg(error.response.data.error);
        this.isBusy = false;
      }
    },
    async getLineups() {
      try {
        const response = await Fixtures.getLineups(this.match_id);

        this.homeTeam.roster = response.data.lineups.home_team_starters.filter(
          (e) =>
            e.events.filter((ev) => ev.event_name === "substitution-out")
              .length === 0
        );
        this.awayTeam.roster = response.data.lineups.away_team_starters.filter(
          (e) =>
            e.events.filter((ev) => ev.event_name === "substitution-out")
              .length === 0
        );
        this.homeTeam.roster = [
          ...this.homeTeam.roster,
          ...response.data.lineups.home_team_subs.filter(
            (e) =>
              e.events.filter((ev) => ev.event_name === "substitution-in")
                .length !== 0
          ),
        ];
        this.awayTeam.roster = [
          ...this.awayTeam.roster,
          ...response.data.lineups.away_team_subs.filter(
            (e) =>
              e.events.filter((ev) => ev.event_name === "substitution-in")
                .length !== 0
          ),
        ];
        this.homeSubs = response.data.lineups.home_team_subs.filter(
          (e) =>
            e.events.filter((ev) => ev.event_name === "substitution-in")
              .length === 0
        );
        this.homeSubs = [
          ...this.homeSubs,
          ...response.data.lineups.home_team_starters.filter(
            (e) =>
              e.events.filter((ev) => ev.event_name === "substitution-out")
                .length !== 0
          ),
        ];
        this.awaySubs = response.data.lineups.away_team_subs.filter(
          (e) =>
            e.events.filter((ev) => ev.event_name === "substitution-in")
              .length === 0
        );

        this.awaySubs = [
          ...this.awaySubs,
          ...response.data.lineups.away_team_starters.filter(
            (e) =>
              e.events.filter((ev) => ev.event_name === "substitution-out")
                .length !== 0
          ),
        ];
        if (
          response.data.lineups.home_team_starters.length === 0 &&
          response.data.lineups.away_team_starters.length === 0
        ) {
          this.rosterCreated = false;
          return;
        }
        this.rosterCreated = true;
      } catch (error) {
        this.failedmsg(error.response.data.message);
      }
    },
    async getEvents() {
      try {
        const response = await Fixtures.getEvents(this.match_id);
        this.events = response.data.events;
        this.refresh = !this.refresh;
      } catch (error) {
        if(error.response.data.error === "No match events") return ;
        this.failedmsg(error.response.data.error);
      }
    },
    async changeStatus() {
      try {
        await Fixtures.changeStatus(
          this.match_id,
          this.phases[++this.match_status].phase_short
        );
        this.getMatch();
      } catch (error) {
        this.failedmsg(error.response.data.error);
      }
    },
    handleResponse(teamId) {
      console.log("Received response from modalYellowCard or red:", teamId);
      this.getLineups();
      this.getEvents();
    },
    startTimer() {
      if (this.canEdit) {
        setTimeout(() => {
          if (this.timer.seconds > 58) {
            this.timer.minutes += 1;
            this.timer.seconds = -1;
            if (this.timer.minutes === 45 || this.timer.minutes === 90) {
              this.timer.seconds = 0;
              this.changeStatus();
              return;
            }
          }
          this.timer.seconds++;
          this.startTimer();
        }, 1000);
      }
    },
    score() {
      //team = 1 scoreHome, 0 - scoreAway
      this.getEvents();
      this.getMatch();
    },
    modalScoreGoal(team) {
      this.eventTeam = team;
      this.$bvModal.show("score_goal");
    },
    modalYellowCard(team) {
      this.eventTeam = team;
      this.$bvModal.show("yellow_card");
    },
    modalRedCard(team) {
      this.eventTeam = team;
      this.$bvModal.show("red_card");
    },
    modalSubstitution(team) {
      this.eventTeam = team;
      this.$bvModal.show("substitution");
    },
    modalMOTM(){
      this.$bvModal.show("motm");
    },
    getStatus() {
      if (this.match.progress.is_FT === "Y") {
        return 4;
      }
      if (this.match.progress.is_SH === "Y") {
        return 3;
      }
      if (this.match.progress.is_HT === "Y") {
        return 2;
      }
      if (this.match.progress.is_KO === "Y") {
        return 1;
      }
      return 0;
    },
    failedmsg(msg) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: msg,
        showConfirmButton: false,
        timer: 50000,
      });
    },
  },
  watch: {
    canEdit(e) {
      e && this.startTimer();
    },
    match_status(s) {
      if (s === 0 || s === 2 || s === 4) {
        this.canEdit = false;
      } else this.canEdit = true;
    },
  },
  middleware: "authentication",
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div
      class="d-flex gap-2 justify-content-center container bg-white mw-75 mb-4 py-2 shadow rounded"
    >
      <button
        class="btn btn-success w-100"
        @click="changeStatus"
        :disabled="match_status === 4 || !rosterCreated"
      >
        {{ phases[match_status + 1].matchTime }}
      </button>
      <button
        class="btn btn-primary w-100"
        v-if="match_status === 4"
        @click="modalMOTM()"
      >
        <!-- {{ isBusy ? "Creating..." : "Create Roster" }} -->
        {{"Man Of The Match"}}
      </button>
      <button v-else
        class="btn btn-primary w-100"
        @click="createRoster"
        :disabled="rosterCreated"
      >
        {{ isBusy ? "Creating..." : "Create Roster" }}
      </button>
    </div>
    <div
      class="container bg-white mw-75 d-flex justify-content-center rounded p-4 shadow"
    >
      <div class="d-flex align-items-baseline">
        <div class="text-center logo-cover">
          <img
            :src="homeTeam.home_team_logo"
            :alt="homeTeam.home_team_name"
            class="rounded-circle w-50"
          />
          <p>{{ homeTeam.home_team_name }}</p>
        </div>
        <div class="text-center d-flex flex-column align-items-center">
          <div class="d-flex gap-4 align-items-end">
            <h3>{{ homeTeam.score }}</h3>
            <h3>-</h3>
            <h3>{{ awayTeam.score }}</h3>
          </div>
          <p class="mb-1 d-flex">
            <span>
              {{
                timer.minutes.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })
              }}
            </span>
            :
            <span>
              {{
                timer.seconds.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })
              }}
            </span>
          </p>
          <!-- <p class="text-sm mb-1">{{ phases[match_status].time }}</p> -->
          <p>{{ phases[match_status].matchTime }}</p>
        </div>
        <div class="text-center logo-cover">
          <img
            :src="awayTeam.away_team_logo"
            :alt="awayTeam.away_team_name"
            class="rounded-circle w-50"
          />
          <p>{{ awayTeam.away_team_name }}</p>
        </div>
      </div>
    </div>
    <div
      class="d-flex container bg-white shadow mw-75 justify-content-around mt-4 py-2 rounded gap-2"
    >
      <div class="w-100 px- d-flex flex-column gap-2">
        <button
          :disabled="!rosterCreated || !canEdit"
          id="show-btn"
          @click="modalScoreGoal(0)"
          class="btn-success btn w-100"
        >
          Score
        </button>
        <button
          :disabled="!rosterCreated || !canEdit"
          @click="modalYellowCard(0)"
          class="btn-warning btn w-100"
        >
          Yellow Card
        </button>
        <button
          :disabled="!rosterCreated || !canEdit"
          @click="modalRedCard(0)"
          class="btn-danger btn w-100"
        >
          Red Card
        </button>
        <button
          :disabled="!rosterCreated || !canEdit"
          @click="modalSubstitution(0)"
          class="btn-secondary btn w-100"
        >
          Substitution
        </button>
      </div>
      <div class="d-flex" style="height: inherit;">
        <div class="vr"></div>
      </div>
      <div class="w-100 px- d-flex flex-column gap-2">
        <button
          :disabled="!rosterCreated || !canEdit"
          @click="modalScoreGoal(1)"
          class="btn-success btn w-100"
        >
          Score
        </button>
        <button
          :disabled="!rosterCreated || !canEdit"
          @click="modalYellowCard(1)"
          class="btn-warning btn w-100"
        >
          Yellow Card
        </button>
        <button
          :disabled="!rosterCreated || !canEdit"
          @click="modalRedCard(1)"
          class="btn-danger btn w-100"
        >
          Red Card
        </button>
        <button
          :disabled="!rosterCreated || !canEdit"
          @click="modalSubstitution(1)"
          class="btn-secondary btn w-100"
        >
          Substitution
        </button>
      </div>
    </div>
    <Events :events="events" :key="refresh"/>
    <modalScoreGoal
      :team="eventTeam"
      :lineupHome="homeTeam.roster"
      :lineupAway="awayTeam.roster"
      :match_id="match_id"
      @response="score"
    ></modalScoreGoal>
    <modalYellowCard
      :team="eventTeam"
      :lineupHome="homeTeam.roster"
      :lineupAway="awayTeam.roster"
      :match_id="match_id"
      @response="handleResponse"
    ></modalYellowCard>
    <modalRedCard
      :team="eventTeam"
      :lineupHome="homeTeam.roster"
      :lineupAway="awayTeam.roster"
      :match_id="match_id"
      @response="handleResponse"
    >
    </modalRedCard>
    <modalSubstitution
      :team="eventTeam"
      :lineupHome="homeTeam.roster"
      :homeSubs="homeSubs"
      :awaySubs="awaySubs"
      :lineupAway="awayTeam.roster"
      :match_id="match_id"
      @response="handleResponse"
    >
    </modalSubstitution>
    <modalMOTM
      :lineupHome="homeTeam.roster"
      :lineupAway="awayTeam.roster"
      :match_id="match_id"
      @response="handleResponse"
    >
    </modalMOTM>
  </Layout>
</template>

<style>
p {
  margin: 0;
}
.logo-cover {
  width: 200px;
}
.loading {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  color: transparent;
  background-color: grey;
}
</style>
