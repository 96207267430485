<script>
import Teams from "@/services/Teams";
import Swal from "sweetalert2";
import Fixtures from "../../../services/Fixtures";

export default {
  props: {
    team: Number, //0 for home, 1 for away
    match_id: String,
    lineupHome: Array,
    lineupAway: Array,
    homeSubs: Array,
    awaySubs: Array,
  },
  emits: ["response"],
  data() {
    return {
      csrf_token: localStorage.getItem("csrf_token"),
      showModal: false,
      tryingToEdit: false,
      match_minute: null,
      player_id: null,
      second_player_id: null,
      tryingToSubmit: false,
    };
  },
  computed: {
    players() {
      return this.team ? this.lineupAway : this.lineupHome;
    },
    subPlayers() {
      return this.team ? this.awaySubs : this.homeSubs;
    },
    team_id() {
      return this.team;
    },
  },
  // mounted() {
  //     this.getCompetitionRounds();
  // },
  methods: {
    //this.tryingtosubmit
    async addEvent() {
      try {
        this.tryingToSubmit = true;
        const response = await Fixtures.addEventSubstitution(
          this.match_id,
          this.player_id,
          "substitution",
          this.team_id,
          this.second_player_id
        );
        // this.$emit("response", this.team_id);

        this.successmsg();
        this.tryingToSubmit = false;
        this.closeModal();
        this.$emit("response");
      } catch (error) {
        this.failedmsg(error.response.data.error);
        this.tryingToSubmit = false;
      }
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    closeModal() {
      this.showModal = false;
    },

    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Change has been saved",
        showConfirmButton: false,
        timer: 2000,
      });
    },

    failedmsg(msg) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: msg,
        showConfirmButton: false,
        timer: 50000,
      });
    },
  },
};
</script>

<template>
  <b-modal
    id="substitution"
    v-model="showModal"
    :title="team_id ? 'Substitution - Away' : 'Substitution - Home'"
    title-class="text-black font-18"
    body-class="p-3"
    hide-footer
    centered
  >
    <form @submit.prevent="addEvent">
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label class="control-label form-label">Player in</label>
            <select
              v-model="player_id"
              class="form-control form-select"
              name="player_id"
              type="select"
            >
              <b-form-select-option :value="null"
                >Please select a player</b-form-select-option
              >
              <option
                v-for="player in subPlayers"
                :key="player.player_id"
                :value="player.player_id"
              >
                {{ player.player_name }}
              </option>
            </select>
          </div>
          <div class="mb-3">
            <label class="control-label form-label">Player out</label>
            <select
              v-model="second_player_id"
              class="form-control form-select"
              name="second_player_id"
              type="select"
            >
              <b-form-select-option :value="null"
                >Please select a player</b-form-select-option
              >
              <option
                v-for="player in players"
                :key="player.player_id"
                :value="player.player_id"
              >
                {{ player.player_name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="text-end pt-5 mt-3">
        <b-button variant="light" @click="closeModal">Close</b-button>
        <b-button
          type="submit"
          variant="primary"
          class="ms-3"
          :disabled="tryingToSubmit"
          @click="$emit('response', team)"
        >
          {{ this.tryingToSubmit ? "Loading..." : "Submit" }}
        </b-button>
      </div>
      <input type="hidden" name="csrf_token" v-model="csrf_token" />
    </form>
  </b-modal>
</template>
