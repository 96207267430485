<script>
import EventsItem from "./EventsItem.vue";

export default {
  components: { EventsItem },
  props: {
    events: Array,
  },
  data() {
    return {};
  },
};
</script>
<template>
  <div class="bg-white mt-4 px-4 py-2 rounded mx-auto  min-w-50 shadow">
    <div
      v-for="event in events"
      :key="event.id"
    >
      <EventsItem
        :eventName="event.name"
        :home_away="event.team"
        :eventMinute="event.event_minute"
        :eventPlayer="event.player_short"
        :eventCode="event.event_code"
        :eventSecondPlayer="event.second_player_short"
      />
    </div>
  </div>
</template>

<style>
p {
  margin: 0;
}

svg {
  width: 20px;
}
</style>
